<template>
	<div>
		<div class="row my-4">
			<h1 class="col-sm-12 text-right">Clientes</h1>
		</div>

		<DataGrid :config="dataGridConfig" :data="partes" :select="seleccionar" @actions="dataGridActions">
			<div class="row">
				<div v-if="$auth.can('administrador_de_garantias','crear_usuarios')" class="col-sm-2"><button class="btn secondary-btn" @click="modal_flag=1">Crear parte</button></div>
				<div v-if="$auth.can('administrador_de_garantias','editar_usuarios')" class="col-sm-2"><button class="btn complementary-btn" @click="editar_parte">Editar parte</button></div>
				<div v-if="$auth.can('administrador_de_garantias','listar_usuarios')" class="col-sm-2"><button class="btn complementary-principal-btn" @click="ver_parte">Ver parte</button></div>
				<div class="col-sm-2"><button class="btn warning-btn" @click="obtener_partes">Recargar</button></div>
			</div>
		</DataGrid>

		<Modal v-if="modal_flag != 0" :options="{width: '90vw', name: 'parte_modal',close:true}" @close="cancelar_parte">
			<div class="title">{{ titulo_modal }}</div>
			<div class="body">
				<fieldset>
					<legend>Datos generales</legend>

					<div class="row form-group">
						<label for="parte.persona" class="col-form-label col-sm-2">Persona</label>
						<div class="col-sm-4">
							<select v-model="usuario.tipo" name="parte.persona" id="parte.persona" class="form-control">
								<option value="Física">Física</option>
								<option value="Física a actividad empresarial">Física a actividad empresarial</option>
								<option value="Moral">Moral</option>
							</select>
						</div>
						<label for="parte.persona" class="col-form-label col-sm-2">Nombre</label>
						<div class="col-sm-4"><input v-model="usuario.nombre" type="text" name="parte.persona" id="parte.persona" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="parte.rfc" class="col-form-label col-sm-2">RFC</label>
						<div class="col-sm-4"><input v-model="usuario.rfc" type="text" name="parte.rfc" id="parte.rfc" class="form-control"></div>
						<label for="parte.empresa" class="col-form-label col-sm-2">Empresa</label>
						<div class="col-sm-4"><input v-model="usuario.empresa" type="text" name="parte.empresa" id="parte.empresa" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="parte.nacionalidad" class="col-form-label col-sm-2">Nacionalidad</label>
						<div class="col-sm-4"><input v-model="usuario.nacionalidad" type="text" name="parte.nacionalidad" id="parte.nacionalidad" class="form-control"></div>
						<label for="parte.personas_politicamente_expuestas" class="col-form-label col-sm-2">Personas politicamente expuestas (PEP's)</label>
						<div class="col-sm-4">
							<SwitchBPB v-model="usuario.persona_peps" name="parte.personas_politicamente_expuestas" id="parte.personas_politicamente_expuestas" textoff="No" texton="Sí" value="1" type="primary" />
						</div>
					</div>
					<div class="row form-group">
						<label for="parte.personas_alto_riesgo" class="col-form-label col-sm-2">Clientes con actividades de alto riesgo</label>
						<div class="col-sm-4">
							<SwitchBPB v-model="usuario.persona_ato_riesgo" name="parte.personas_alto_riesgo" id="parte.personas_alto_riesgo" textoff="No" texton="Sí" value="1" type="primary" />
						</div>
					</div>
				</fieldset>

				<fieldset>
					<legend>Métodos de contacto</legend>

					<table class="mb-4">
						<tr>
							<th>Tipo</th>
							<th>Valor</th>
							<th v-if="modal_flag != 3">&nbsp;</th>
						</tr>
						<template v-if="contactos.length > 0">
						<tr v-for="contacto in contactos">
							<td>{{ contacto.tipo }}</td>
							<td>{{ contacto.valor }}</td>
							<td v-if="modal_flag != 3">
								<img :data-id="contacto.id" :data-valor="contacto.valor" class="icon-eliminar" src="https://static.bienparabien.com/constelacion/img/trash_01_b.png" alt="Eliminar" title="Eliminar" @click="eliminar_contacto">
							</td>
						</tr>
						</template>
						<tr v-else>
							<td colspan="3">No hay métodos de contacto</td>
						</tr>
					</table>

					<div class="row form-group" v-if="modal_flag != 3">
						<div class="col-sm-3">
							<select ref="nuevo_contacto_tipo" name="nuevo_contacto_tipo" id="nuevo_contacto_tipo" class="form-control">
								<option value="tipo_valor">Tipo de contacto</option>
								<option value="email">Email</option>
								<option value="celular">Celular</option>
								<option value="telefono_casa">Teléfono casa</option>
								<option value="telefono_trabajo">Teléfono trabajo</option>
							</select>
						</div>
						<div class="col-sm-7"><input ref="nuevo_contacto_valor" type="text" name="nuevo_contacto_valor" id="nuevo_contacto_valor" class="form-control" placeholder="Valor"></div>
						<div class="col-sm-2"><button class="btn secondary-btn" @click="agregar_contacto">Agregar</button></div>
					</div>
				</fieldset>

				<fieldset v-if="false">
					<legend>Domicilios</legend>

					<template v-if="domicilios.length > 0">
						<div v-for="domicilio in domicilios" class="direcciones">
							<div class="row form-group">
								<div class="col-sm-2">Calle</div>
								<div class="col-sm-4">{{ domicilio.calle }}</div>
								<div class="col-sm-2">Colonia</div>
								<div class="col-sm-4">{{ domicilio.colonia }}</div>
							</div>
							<div class="row form-group">
								<div class="col-sm-2">No Interior</div>
								<div class="col-sm-4">{{ domicilio.no_interior }}</div>
								<div class="col-sm-2">No Exterior</div>
								<div class="col-sm-4">{{ domicilio.no_exterior }}</div>
							</div>
							<div class="row form-group">
								<div class="col-sm-2">Delegación</div>
								<div class="col-sm-4">{{ domicilio.delegacion }}</div>
								<div class="col-sm-2">Estado</div>
								<div class="col-sm-4">{{ domicilio.estado }}</div>
							</div>
							<div class="row form-group">
								<div class="col-sm-2">CP</div>
								<div class="col-sm-4">{{ domicilio.cp }}</div>
								<div class="col-sm-2">Pais</div>
								<div class="col-sm-4">{{ domicilio.pais }}</div>
							</div>
							<div class="row form-group">
								<div class="col-sm-2">Direccion principal</div>
								<div class="col-sm-4">{{ domicilio.domicilio_principal ? 'Sí' : 'No' }}</div>
							</div>
						</div>
					</template>
					<div v-else class="mb-4">No hay domicilios</div>

					<template v-if="modal_flag != 3">
					<div class="row form-group">
						<label for="parte_direccion_calle" class="col-form-label col-sm-2">Calle</label>
						<div class="col-sm-4"><input v-model="direccion.calle" type="text" name="parte_direccion_calle" id="parte_direccion_calle" class="form-control"></div>
						<label for="parte_direccion_colonia" class="col-form-label col-sm-2">Colonia</label>
						<div class="col-sm-4"><input v-model="direccion.colonia" type="text" name="parte_direccion_colonia" id="parte_direccion_colonia" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="parte_direccion_no_interior" class="col-form-label col-sm-2">No Interior</label>
						<div class="col-sm-4"><input v-model="direccion.no_interior" type="text" name="parte_direccion_no_interior" id="parte_direccion_no_interior" class="form-control"></div>
						<label for="parte_direccion_no_exterior" class="col-form-label col-sm-2">No Exterior</label>
						<div class="col-sm-4"><input v-model="direccion.no_exterior" type="text" name="parte_direccion_no_exterior" id="parte_direccion_no_exterior" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="parte_direccion_delegacion" class="col-form-label col-sm-2">Delegación</label>
						<div class="col-sm-4"><input v-model="direccion.delegacion" type="text" name="parte_direccion_delegacion" id="parte_direccion_delegacion" class="form-control"></div>
						<label for="parte_direccion_estado" class="col-form-label col-sm-2">Estado</label>
						<div class="col-sm-4"><input v-model="direccion.estado" type="text" name="parte_direccion_estado" id="parte_direccion_estado" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="parte_direccion_cp" class="col-form-label col-sm-2">CP</label>
						<div class="col-sm-4"><input v-model="direccion.cp" type="text" name="parte_direccion_cp" id="parte_direccion_cp" class="form-control"></div>
						<label for="parte_direccion_pais" class="col-form-label col-sm-2">Pais</label>
						<div class="col-sm-4"><input v-model="direccion.pais" type="text" name="parte_direccion_pais" id="parte_direccion_pais" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="parte_direccion_principal" class="col-form-label col-sm-2">Domicilio principal</label>
						<div class="col-sm-4">
							<SwitchBPB v-model="direccion.principal" name="parte_direccion_principal" id="parte_direccion_principal" type="primary" />
						</div>
					</div>
					<div class="row form-group">
						<div class="col-sm-2 offset-sm-10"><button class="btn secondary-btn" @click="agregar_direccion">Agregar</button></div>
					</div>
					</template>
				</fieldset>
			</div>
			<div class="footer" v-if="modal_flag != 3">
				<div class="row">
					<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="guardar_parte">Guardar parte</button></div>
					<div class="col-sm-2"><button class="btn danger-btn" @click="cancelar_parte">Cancelar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'
	import SwitchBPB from '@/components/Form/Switch'

	import api from '@/apps/garantias/api/usuarios'

	export default {
		components: {
			DataGrid, Modal, SwitchBPB
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'partes'
					,cols: {
						nombre: 'Nombre'
						,rfc: 'RFC'
						,created_at: 'Creación'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						created_at: function(val, row, vue) {
							return vue.$moment(val).format('YYYY-MM-DD')
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccionadas: []
				,partes: []
				,usuario: {
					relacion: null
					,lugar: null
					,persona: null
					,fecha_registro: null
					,nombre: null
					,rfc: null
					,empresa: null
					,cargo: null
					,nacionalidad: null
					,persona_peps: 0
					,persona_ato_riesgo: 0
					,contactos: []
					,domicilios: []
				}
				,direccion: {
					calle: null
					,colonia: null
					,no_interior: null
					,no_exterior: null
					,delegacion: null
					,estado: null
					,cp: null
					,pais: null
					,principal: 0
				}
				,modal_flag: 0
			}
		}
		,methods: {
			dataGridActions: function(tipo, data) {
				if (tipo == 'options') {
					this.options = data;
					this.obtener_partes();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,obtener_partes: async function() {
				try {
					let res = (await api.obtener_partes(this.options)).data;

					this.partes = res.data;
					this.dataGridConfig.paginator.pagina_actual = res.current_page;
					this.dataGridConfig.paginator.total_registros = res.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,agregar_contacto: function() {
				if (
					(this.$refs.nuevo_contacto_tipo.value == 'tipo_valor') ||
					(!this.$refs.nuevo_contacto_valor.value || this.$refs.nuevo_contacto_valor.value == '')
				)
					return this.$helper.showMessage('Error','Tienes que definir el tipo y el valor para agregar un método de contacto','error','alert');

				switch(this.$refs.nuevo_contacto_tipo.value) {
					case 'email':
						if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.$refs.nuevo_contacto_valor.value))
							return this.$helper.showMessage('Error','El email no es válido','error','alert');
						break;
					case 'celular':
						if (!/^([0-9]){10}$/.test(this.$refs.nuevo_contacto_valor.value))
							return this.$helper.showMessage('Error','El teléfono celular no es válido','error','alert');
						break;
					case 'telefono_casa':
						if (!/^([0-9]){10}$/.test(this.$refs.nuevo_contacto_valor.value))
							return this.$helper.showMessage('Error','El teléfono de casa no es válido','error','alert');
						break;
					case 'telefono_trabajo':
						if (!/^([0-9]){10}$/.test(this.$refs.nuevo_contacto_valor.value))
							return this.$helper.showMessage('Error','El teléfono de trabajo no es válido','error','alert');
						break;
				}

				for(let i=0; i<this.usuario.contactos.length; i++) {
					if (this.usuario.contactos[i].valor == this.$refs.nuevo_contacto_valor.value) {
						i = this.usuario.contactos.length;
						return this.$helper.showMessage('Error','Ya existe un método de contacto con el valor: '+this.$refs.nuevo_contacto_valor.value,'error','alert');
					}
				}

				this.usuario.contactos.push({tipo: this.$refs.nuevo_contacto_tipo.value, valor: this.$refs.nuevo_contacto_valor.value});

				this.$refs.nuevo_contacto_tipo.value = 'tipo_valor';
				this.$refs.nuevo_contacto_valor.value = null;
			}
			,eliminar_contacto: async function(e) {
				try {
					let id = e.target.getAttribute('data-id');

					this.usuario = (await api.eliminar_contacto(this.usuario.id, id)).data;
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,agregar_direccion: function() {
				let errores = [];

				if (this.direccion.calle == '' || !this.direccion.calle)
					errores.push('Debes definir la calle');

				if (this.direccion.colonia == '' || !this.direccion.colonia)
					errores.push('Debes definir la colonia');

				if (this.direccion.no_exterior == '' || !this.direccion.no_exterior)
					errores.push('Debes definir el número exterior, si no existe indica "S/N"');

				if (this.direccion.delegacion == '' || !this.direccion.delegacion)
					errores.push('Debes definir la delegación o municipio');

				if (this.direccion.estado == '' || !this.direccion.estado)
					errores.push('Debes definir el estado');

				if (this.direccion.cp == '' || !this.direccion.cp)
					errores.push('Debes definir el código postal');

				if (this.direccion.pais == '' || !this.direccion.pais)
					errores.push('Debes definir el país');

				if (errores.length > 0) {
					let tmpError = 'Se encontraron los siguientes errores: <ul>';

					errores.forEach(err => {
						tmpError += '<li>'+err+'</li>';
					});

					tmpError += '</ul>';
					return this.$helper.showMessage('Error',tmpError,'error','alert');
				}

				this.parte.domicilios.push({
					calle: this.direccion.calle
					,colonia: this.direccion.colonia
					,no_interior: this.direccion.no_interior
					,no_exterior: this.direccion.no_exterior
					,delegacion: this.direccion.delegacion
					,estado: this.direccion.estado
					,cp: this.direccion.cp
					,pais: this.direccion.pais
					,domicilio_principal: this.direccion.principal
				});

				this.direccion = {
					calle: null
					,colonia: null
					,no_interior: null
					,no_exterior: null
					,delegacion: null
					,estado: null
					,cp: null
					,pais: null
					,principal: 0
				}
			}
			,cancelar_parte: function() {
				this.usuario = {
					relacion: null
					,lugar: null
					,persona: null
					,fecha_registro: null
					,nombre: null
					,rfc: null
					,empresa: null
					,cargo: null
					,nacionalidad: null
					,persona_peps: 0
					,persona_ato_riesgo: 0
					,contactos: []
					,domicilios: []
				}

				this.modal_flag = 0;
				this.seleccionar = false;
			}
			,guardar_parte: async function() {
				try {
					let res;
					if (this.modal_flag == 1)
						res = (await api.crear_parte(this.usuario)).data;
					else
						res = (await api.editar_parte(this.usuario.id, this.usuario)).data;

					this.$log.info('res', res);

					this.usuario = res;
					this.cancelar_parte();
					this.obtener_partes();
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,editar_parte: function() {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Tienes que seleccionar una parte a editar','error','alert');

				this.usuario = this.seleccionadas[0];
				this.modal_flag = 2;
			}
			,ver_parte: function() {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Tienes que seleccionar una parte a editar','error','alert');

				this.usuario = this.seleccionadas[0];
				this.modal_flag = 3;

				setTimeout(() => {
					document.querySelectorAll('.parte_modal input, .parte_modal select').forEach(input => {
						input.disabled = true;
					},50);
				})
			}
		}
		,computed: {
			contactos: function() {
				let contactos = [];

				for(let i=0; i<this.usuario.contactos.length; i++) {
					if (!this.usuario.contactos[i].deleted_id)
						contactos.push(this.usuario.contactos[i]);
				}

				return contactos;
			}
			,domicilios: function() {
				let domicilios = [];

				for(let i=0; i<this.usuario.domicilios.length; i++) {
					if (!this.usuario.domicilios[i].deleted_id)
						domicilios.push(this.usuario.domicilios[i]);
				}

				return domicilios;
			}
			,titulo_modal: function() {
				switch(this.modal_flag) {
					case 1:
						return 'Crear parte';
						break;
					case 2:
						return 'Editar parte';
						break;
					case 3:
						return 'Ver parte';
						break;
				}
			}
		}
	}
</script>

<style lang="scss">
	table {
		width: 100%;

		tr {
			th {
				background-color: #262639;
				color: #fff;
				padding: 5px 10px;
			}

			td {
				padding: 5px 10px;

				img {
					width: 20px;
				}
			}
		}

		tr:nth-child(2n+1) {
			background-color: #E6E6E6;
		}
	}

	.direcciones {
		padding: 10px 20px;
		border: solid 1px #E6E6E6;
		margin-bottom: 10px;

		.col-sm-2 {
			background-color: #E6E6E6;
		}
	}

	.direcciones:nth-child(2n+1) {
		background-color: #E6E6E6;

		.col-sm-2 {
			background-color: #fff;
		}
	}
</style>